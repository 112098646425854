<template>
<div :key="helperKey">
  <div class="div_title">
    <context :context=cate />
    <policies/>
  </div>
  <div v-if="this.api.getCookies('login') === 'true' || this.api.getCookies('login') === true">
    <div ref="section1" class="group">
      <div class="question">
        <p>대행신청을 하시기 위해서는 아래 내용에 동의해주셔야 됩니다.</p>
        <p><strong>1. 구매자님께서 요청하신 상품의 판매자가 사기 판매자이거나 파손된 물건 등 불미스러운 어떠한 일이 발생해도 해외직구의 특성상 GEEK9에서 어떠한 책임도 지지 않습니다. 동의하셔야 대행신청이 가능합니다.</strong></p>
        <p><strong>2. 카톡이나 문자/전화로는 응대를 하지 않습니다. 모든 문의는 문의 게시판에 남겨주시기 바랍니다.</strong></p>
      </div>
      <span class="notice link" @click="click({type:'section1-notice'})">자주묻는질문</span>
      <div class="answer">
        동의하시나요?
        <input ref="section1_y" class="helper-button" type="button" value="네" @click="click({type:'section1', value:'y'})">
        <input ref="section1_n" class="helper-button" type="button" value="아니요" @click="click({type:'section1', value:'n'})">
      </div>
    </div>
    <div ref="section2" class="group" style="display:none">
      <div class="question">
        대행유형을 선택해주세요.<br>
        <strong>구매대행</strong> : 해외에서 구매하는 것과 한국으로의 배송까지 GEEK9에서 대신 처리합니다.<br>
        <strong>배송대행</strong> : 해외에서 구매하는 건 소비자님께서 하시고, 한국으로의 배송만 GEEK9에서 처리합니다..<br>
        <strong>결제대행</strong> : 특정 국가의 계좌/결제정보를 GEEK9에 알려주시면 GEEK9에서 결제한 후에 결제내역 보여드립니다.<br>
      </div>
      <span class="notice link" @click="click({type:'section2-notice'})">자주묻는질문</span>
      <div class="answer">
        대행유형
        <input ref="section2_buy" class="helper-button" type="button" value="구매대행" @click="click({type:'section2', value:'buy'})">
        <input ref="section2_dlv" class="helper-button" type="button" value="배송대행" @click="click({type:'section2', value:'dlv'})">
        <input ref="section2_pay" class="helper-button" type="button" value="결제대행" @click="click({type:'section2', value:'pay'})">
        <input ref="section2_mod" class="helper-button" type="button" value="변경" @click="click({type:'section2', value:'mod'})" style="display:none">
      </div>
    </div>
    <!-- receiver -->
    <div ref="section3" class="group" style="display:none;">   
      <div class="question">
        받는 분의 정보를 한글로 정확하게 입력해주세요.<br>
        이메일로 진행상황이 전달되고, 문제 발생 시 폰으로 연락드립니다.<br>
        통관부호를 발급받으실 때 사용하신 성함과 폰번호를 알려주셔야 됩니다.<br>
        정보가 정확하지 않으면 세관에서 문제가 됩니다.<br>
      </div>
      <div class="card">
        <input ref='recentBtn'  type="button" class='helper-button' style='max-width:100px; padding:5px;margin-bottom:10px;margin-right:10px;' @click='recentInfo()' value="최근배송정보">
        <receiver ref="receiver" :isHelper="isHelper" :orderType="this.orderType" @update:orderType="orderType = $event" :country="country" @update:country="country = $event" :mail="mail" @update:mail="mail = $event" :phone="phone" @update:phone="phone = $event" :clearance="clearance" @update:clearance="clearance = $event" :orderName="orderName" @update:orderName="orderName = $event" :addrZip="addrZip" @update:addrZip="addrZip = $event" :addrRoad="addrRoad" @update:addrRoad="addrRoad = $event" :addrDetail="addrDetail" @update:addrDetail="addrDetail = $event" />
      </div>
      <span class="notice link" @click="click({type:'section3-notice'})">자주묻는질문</span>
      <div class="answer">
        <input ref="section3_y" class="helper-button" type="button" value="완료" @click="click({type:'section3', value:'y'})">
        <input ref="section3_mod" class="helper-button" type="button" value="변경" @click="click({type:'section3', value:'mod'})" style="display:none">
      </div>
    </div>

    <!-- 결제대행 -->
    <div ref="section4" class="group" style="display:none;">   
      <div class="question">
        아래 정보를 입력하신 후 요청해주시면 연락드립니다!
      </div>
      <table class="dynamic-table">
        <tr style="height:100px;">
          <td style="width:100%;">
            <table class="order_sub_table" style="table-layout:fixed;">
              <tbody style="text-align: left;">
                <tr style="height:30px;">
                  <td class="container_sub_style">
                    <div class="div_center">
                      <div style="min-width:100px;" v-tooltip="'판매처에 남길 이름'">입금자명*</div>
                      <div style="width:100%;">
                        <input class="normalInput" ref="payName" type="text" >
                      </div>
                    </div>
                  </td>
                  <td class="container_sub_style">
                    <div class="div_center">
                      <div style="min-width:100px;" v-tooltip="'결제완료 후 이체내역을 받으실 폰번호'">폰번호 *</div>
                      <div style="width:100%;">
                        <input class="normalInput" ref="payPhone" type="text" >
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
        <tr>
          <td>
            <textarea ref='payContent' style='margin-top:15px;' v-html="content" />   
          </td>
        </tr>
        <tr>
          <td>
            <label type="button" id="helpPic" style="margin-top:15px;" />
            <label class="attach link" type="button" for="inputFile">파일첨부</label>
            <label ref="helpPicName" class="attach_label" for="inputFile"></label>
            <input type="file" id="inputFile" @change="readFile($event)" style="display:none">
          </td>
        </tr>
      </table>
      <span class="notice link" @click="click({type:'section4-notice'})">자주묻는질문</span>
      <div class="answer">
        <input class="helper-button" type="button" value="결제대행요청" @click="click({type:'section4', value:'y'})">
      </div>
    </div>

    <!-- 구매대행/배송대행 -->
    <div ref="section5" class="group" style="display:none"> 
      <div class="question">
        대행을 맡기실 상품 정보를 입력해주세요!<br>
        함께 주문한 상품은 합배송으로 같이 배송됩니다.<br>
        신청해주시면 홈페이지나 이메일로 비용을 확인하실 수 있습니다.<br>
        구매대행 : 견적을 확인하신 후에 결제해 주시면 GEEK9에서 해외판매자에게 구매를 합니다.<br>
        배송대행 : 구매자님께서 GEEK9의 해외주소지로 보내신 물건이 GEEK9로 도착하면 배송비만 결제해 주시면 됩니다.<br>
        <br>
        <div id="orders">
          <div v-if="items.length === 0" class="main container_no_login no-result margin10" >
            대행 상품을 추가해주세요
          </div>
          <div ref="itemsDiv" v-else id="orders">
            <div v-for="(item, index) in items" :key="index">
              <component :is="item" ref='key' class="card" :idx='index+1' route='' @copy='copy' @remove='remove' />
            </div>
          </div>
          <br>
          <div class="div_center">
            <input ref="addItem" @click="add_item()" class="helper-button" type="button" value="&#43; 상품추가" />
          </div>
        </div>
      </div>
      <span class="notice link" @click="click({type:'section5-notice'})">자주묻는질문</span>
      <div class="answer">
        {{items.length}}건을 {{ this.orderType }} 신청하시겠습니까?
        <input ref="section5_y" class="helper-button" type="button" value="네" @click="click({type:'section5', value:'y'})">
        <input ref="section5_mod" class="helper-button" type="button" value="변경" @click="click({type:'section5', value:'mod'})" style="display:none">
      </div>
    </div>
    <!-- 추가옵션 -->
    <div ref="section6" class="group" style="display:none"> 
      <div class="question">
        추가옵션<br>
        배송비 결제하실 때 함께 청구됩니다.
        <br><br>
        <extaraoption ref="extraOption" class="card main" />
        기타요청사항<br>
        <input class="normalInput" ref="extraEtc" type="text" placeholder="상품옵션/사이즈 등" /> 
      </div>
      <span class="notice link" @click="click({type:'section6-notice'})">자주묻는질문</span>
      <div class="answer">
        <input ref="section6_y" class="helper-button" type="button" value="완료" @click="click({type:'section6', value:'y'})">
        <input ref="section6_mod" class="helper-button" type="button" value="변경" @click="click({type:'section6', value:'mod'})" style="display:none">
      </div>
    </div>

    <div style="text-align:center;">
      <input ref="helperRegBtn" @click="click({type:'reg'})" class="button_regist" type="button" value="주문하기" style="display:none"/>
    </div>
  </div>
  <div v-else >
    <login @login="login" pageKey="order"/> 
    <br>
    <div class="card main no-login-title">
      <span>대행</span>
      <ul>
        <li><a href="https://geek9.kr/faq?idx=7" target="_blank">비용</a></li>
        <li><a href="https://contents.geek9.kr/blog/57" target="_blank">신청방법</a></li>
        <li>주문과 문의는 홈페이지를 통해서만 가능합니다</li>
      </ul>
      <span class="no-login-title">구매대행</span>
      <ul>
        <li>GEEK9에서 해외 구매/결제와 한국으로의 배송까지 모두 처리</li>
        <li>총비용 = 최초구매시대행비 + 한국으로 보내는 배송비</li>
        <li>최초구매시대행비와 한국으로 보내는 배송비는 따로 청구</li>
      </ul>
      <span class="no-login-title">배송대행</span>
      <ul>
        <li>해외 구매/결제는 고객이, 한국으로의 배송은 GEEK9에서 처리</li>
        <li>총비용 = 한국으로 보내는 배송비</li>
        <li>GEEK9 창고에 물건 도착 시 한국으로 보내는 배송비 청구</li>
      </ul>
      <span class="no-login-title">결제대행</span>
      <ul>
        <li>고객이 알려주는 해외결제정보로 GEEK9에서 결제 처리</li>
      </ul>
    </div>
  </div> 
  <Modal v-model='isShow' :close='closeModal' :setModal='setModal' style="z-index: 99999999999;">
    <div class="modal">
      <div class="div_title" style="text-align: left;margin-top:-20px;">
        <span style="color: #ff7f50; ">자주묻는질문</span>
      </div>
      <!-- faq0 -->
      <div id="sub_menu0" class="card main" v-if="subIdx === 0"> <!-- 동의 -->
        <!-- <button id="idx0" class="collapsible" @click="this.title_click(0, 0, '13')">사기거래</button>
        <button id="idx1" class="collapsible" @click="this.title_click(0, 1, '14')">교환/환불/취소</button> -->
        <DataTable v-model:expandedRows="expandedRows" :value="helper0" dataKey="IDX" stripedRows>
          <Column expander style="width: 5rem" />
          <Column field="TITLE" header="" />
          <template #expansion="slotProps">
            <helperc :idx='slotProps.data.IDX'/>
          </template>
        </DataTable>
      </div>
      <!-- faq1 -->
      <div id="sub_menu1" class="card main" v-if="subIdx === 1"> <!-- 유형-구매대행 -->
        <DataTable v-model:expandedRows="expandedRows" :value="helper1" dataKey="IDX" stripedRows>
          <Column expander style="width: 5rem" />
          <Column field="TITLE" header="" />
          <template #expansion="slotProps">
            <helperc :idx='slotProps.data.IDX'/>
          </template>
        </DataTable>
      </div>
      <!-- faq1 -->
      <div id="sub_menu2" class="card main" v-if="subIdx === 2"> <!-- 유형-배송대행 -->
        <DataTable v-model:expandedRows="expandedRows" :value="helper1" dataKey="IDX" stripedRows>
          <Column expander style="width: 5rem" />
          <Column field="TITLE" header="" />
          <template #expansion="slotProps">
            <helperc :idx='slotProps.data.IDX'/>
          </template>
        </DataTable>
      </div>
      <!-- faq2 -->
      <!-- const faq2 = ref([{IDX:3, TITLE:'필수정'}, {IDX:9, TITLE:'배송비'}]) -->
      <div id="sub_menu3" class="card main" v-if="subIdx === 3"> <!-- 받는사람 -->
        <DataTable v-model:expandedRows="expandedRows" :value="helper2" dataKey="IDX" stripedRows>
          <Column expander style="width: 5rem" />
          <Column field="TITLE" header="" />
          <template #expansion="slotProps">
            <helperc :idx='slotProps.data.IDX'/>
          </template>
        </DataTable>
      </div>
      <!-- faq3 -->      
      <!-- const faq3 = ref([{IDX:2, TITLE:'결제대행'}]) -->
      <div id="sub_menu4" class="card main" v-if="subIdx === 4"> <!-- 결제대행 -->
        <DataTable v-model:expandedRows="expandedRows" :value="helper3" dataKey="IDX" stripedRows>
          <Column expander style="width: 5rem" />
          <Column field="TITLE" header="" />
          <template #expansion="slotProps">
            <helperc :idx='slotProps.data.IDX'/>
          </template>
        </DataTable>
      </div>
      <!-- faq4 -->
      <!-- const faq4 = ref([{IDX:17, TITLE:'상품링크'},{IDX:18, TITLE:'상품명'},{IDX:19, TITLE:'사진링크'},{IDX:20, TITLE:'해외가격'},{IDX:21, TITLE:'구매수량'},{IDX:22, TITLE:'주문번호'},{IDX:23, TITLE:'트래킹넘버'} ]) -->
      <div id="sub_menu5" class="card main" v-if="subIdx === 5"> <!-- 구매상품 -->
        <DataTable v-model:expandedRows="expandedRows" :value="helper4" dataKey="IDX" stripedRows>
          <Column expander style="width: 5rem" />
          <Column field="TITLE" header="" />
          <template #expansion="slotProps">
            <helperc :idx='slotProps.data.IDX'/>
          </template>
        </DataTable>
      </div>
      <!-- faq5 -->
      <!-- const faq5 = ref([{IDX:4, TITLE:'원산지증명(FTA 통관)'}, {IDX:24, TITLE:'추가포장'}, {IDX:25, TITLE:'상품사진'}, {IDX:6, TITLE:'세금'}, {IDX:8, TITLE:'추가비용'}, {IDX:15, TITLE:'세관통관'}]) -->
      <div id="sub_menu6" class="card main" v-if="subIdx === 6"> <!-- 추가옵션 -->
        <DataTable v-model:expandedRows="expandedRows" :value="helper5" dataKey="IDX" stripedRows>
          <Column expander style="width: 5rem" />
          <Column field="TITLE" header="" />
          <template #expansion="slotProps">
            <helperc :idx='slotProps.data.IDX'/>
          </template>
        </DataTable>
      </div>
      <div class="ad-container">
        <Adsense
          data-ad-client="ca-pub-6699354447815429" 
          data-ad-slot="8230887469"
          data-ad-format="horizontal"
          data-full-width-responsive="true">
        </Adsense>
      </div>
      <br>
      <!-- <div v-if="idx==='-1' || idx===undefined" class="main container_no_login">
        <span>제목을 클릭해주세요</span>
      </div>
      <div v-else class="card main">
        <helperc :idx='idx'/>
      </div> -->
      <div><Button @click="closeModal()" label="닫기" raised size="small"></Button></div>
    </div>
  </Modal>
  <Modal v-model='isRecentShow' :setModal='setRecentModal' style="z-index: 99999999999;">
    <div class="modal">
      <span style="color:black; font-weight:bold">최근배송정보</span>
      <div id="sub_menu" style="margin-top:20px;margin-bottom:20px;">
        <div v-for="(order, index) in orders" :key="index" >
          <orderinfoc class="content" @closeModal='closeRecentModal' @setModal='setRecentModal' :idx='index+1' :name='order.ORDER_NAME' :clearance='order.CLEARANCE' :addrZip='order.ADDR_ZIP' :addrRoad='order.ADDR_ROAD' :addrDetail='order.ADDR_DETAIL' :mail='order.MAIL' :phone='order.PHONE'/>
        </div>
      </div>
      <div>
        <Button @click="closeRecentModal()" label="닫기" raised size="small"></Button>
      </div>
    </div>
  </Modal>  
</div>
<div class="ad-container">
  <Adsense
    data-ad-client="ca-pub-6699354447815429" 
    data-ad-slot="8230887469"
    data-ad-format="horizontal"
    data-full-width-responsive="true">
  </Adsense>
</div>
</template>

<script>
// import { useMeta } from 'vue-meta'
import router from './router'
import { defineAsyncComponent, ref } from 'vue'
import helperc from './components/content/faq_content.vue'
import item from './components/order/item.vue'
import receiver from './components/order/receiver.vue'
import orderinfoc from './components/content/orderinfo_content.vue';
import extaraoption from './components/order/extaraoption.vue'
import moment from 'moment'
import ssr from './components/common/ssr'
import context from '@/components/common/Context.vue'
import policies from '@/components/common/policies.vue'
import { inject } from 'vue'
import login from '@/components/common/Login.vue'

var items = ref([])

export default {
  emits: ["setCookies", "dialog", "setMenu", "login"],
  mounted() {
    this.addJsonLd()
    this.emitter.on('helperReload', this.reload)  
    this.api.setPage("helper")
    this.$emit("setMenu", {page:'helper'})
    console.log(this.api.getCookies('page'))
  },
  unmounted() {
    this.emitter.off('helperReload', this.reload)  
  },
  data() { 
    return { 
      picturePrice:'0',
      insurancePrice:'0',
      packagePrice:'0',
      idx : '-1',
      subIdx: -1,
      content:"해외은행/결제수단 : (city bank, payme, paypal, HSBC...)\n\n금액 : (화폐단위필수)\n\n수취인이름 : \n\n국가 : \n\n기타 : ",
      helperKey:0,
      url: '',
      name:'',
      picUrl:'',
      fPrice:'',
      amount:'',
      orderNumber:'',
      trackingNumber:'',
      option:'',
      etc:'',
      orderType:'',
      country:'',
      orderName:this.api.getCookies('nickname'),
      clearance:'',
      phone:'',
      mail:this.api.getCookies('mail'),
      addrZip:'',
      addrRoad:'',
      addrDetail:'',
      agree:false,
      isHelper:true,
      orderArray:Array(),
      file: undefined,
      fileName: '',
      sellPrice:0
    } 
  },
  components: {
    helperc, item, receiver, orderinfoc, extaraoption, 
    policies, login,
    context,
    item_tag: defineAsyncComponent(() => import("./components/order/item.vue"))
  },
  setup() {
    const setMeta = inject('setMeta')
    const title = 'GEEK9 > 해외직구도우미'
    const keywords = '직구,해외직구,구매대행,배송대행,배대지,해외운송,이사,geek9,3PL,풀필먼트,인기상품,해외상품,중고,해외인기상품,vinted,carousell'
    const description = '복잡한 해외직구를 자세한 설명과 함께 쉽게 신청해보세요!'
    const geek9Url = ssr.GEEK9_URL + 'helper'
    setMeta({
      title: title,
      keywords: keywords,
      description: description,
      'og:title': title,
      'og:description': description,
      'og:image': 'https://geek9.kr/img/logo_big.e0186dc8.jpg',
      'og:url': geek9Url,
      'canonical': geek9Url
    })
    const addJsonLd = () => {
      let flag = true
      document.querySelectorAll('script').forEach(el => {
        if (el.getAttribute('type') === 'application/ld+json' ) {
          flag = false
          return
        }
      })
      if(flag === true) {
        const script = document.createElement('script')
        script.setAttribute('type', 'application/ld+json')
        script.textContent = JSON.stringify(
          {
            "@context": "https://schema.org",
            "@graph": [
              ssr.getWebPage(geek9Url, title, ssr.GEEK9_URL, description),
            ]
          }
        )
        document.head.appendChild(script)
      }
    }
    const add_item = () => {
      items.value.push('item_tag')
    }
    const isShow = ref(false)
    function showModal () {
      isShow.value = true
    }
    function closeModal () {
      isShow.value = false
    }
    const isRecentShow = ref(false)
    let orders = ref([])
    async function showRecentModal () {
      isRecentShow.value = true
      const id = this.api.getCookies('id')
      
      await this.api.axiosGql('SELECT_ORDER_INFO_LIST', {type:'SELECT_ORDER_INFO_LIST', id: ""+id})
      .then( (res) => {
        orders.value = res.data
      })
    }
    function closeRecentModal () {
      isRecentShow.value = false
    }
    const helper0 = ref([{IDX:'13', TITLE:'사기거래'}, {IDX:'14', TITLE:'교환/환불/취소'}])
    const helper1 = ref([{IDX:'0', TITLE:'구매대행'}, {IDX:'1', TITLE:'배송대행'}, {IDX:'10', TITLE:'배송기간'}, {IDX:'12', TITLE:'진행상황'}, {IDX:'7', TITLE:'수수료'}, {IDX:'5', TITLE:'예상견적'}])
    const helper2 = ref([{IDX:'3', TITLE:'필수정보'}, {IDX:'9', TITLE:'배송비'}])
    const helper3 = ref([{IDX:'2', TITLE:'결제대행'}])
    const helper4 = ref([{IDX:'17', TITLE:'상품링크'},{IDX:'18', TITLE:'상품명'},{IDX:'19', TITLE:'사진링크'},{IDX:'20', TITLE:'해외가격'},{IDX:'21', TITLE:'구매수량'},{IDX:'22', TITLE:'주문번호'},{IDX:'23', TITLE:'트래킹넘버'} ])
    const helper5 = ref([{IDX:'4', TITLE:'원산지증명(FTA 통관)'}, {IDX:'24', TITLE:'추가포장'}, {IDX:'25', TITLE:'상품사진'}, {IDX:'6', TITLE:'세금'}, {IDX:'8', TITLE:'추가비용'}, {IDX:'15', TITLE:'세관통관'}])
    const expandedRows = ref({})
    const cate = ref([
      { label: '대행신청'},
      { label: '대행도우미', route: '/helper' }
    ])
    return {
      closeModal, showModal, closeRecentModal, showRecentModal, isShow, isRecentShow, add_item, items, orders, 
      // showRegistModal, closeRegistModal, isRegist, loginId,
      helper0, helper1, helper2, helper3, helper4, helper5, expandedRows, cate, addJsonLd
    }
  },
  methods: {
    login() {
      this.$emit('login')
    },
    section6(param) {
      const isClose = param.isClose
      const isReset = param.isReset
      
      if(isClose === true) {
        this.$refs.section6_y.style.display = 'none'
        this.$refs.section6.style.background = '#FFF7F6'
        this.$refs.section6_mod.style.display = ''
        this.$refs.helperRegBtn.style.display = ''
      } else {
        this.$refs.section6_y.style.display = ''
        this.$refs.section6.style.background = 'white'
        this.$refs.section6_mod.style.display = 'none'
        this.$refs.helperRegBtn.style.display = 'none'
      }
      let extraOpt = this.$refs.extraOption.$refs
      if(extraOpt !== undefined) {
        // extraOpt.chk_fta.disabled = !isReset
        extraOpt.chk_packing.disabled = !isReset
        extraOpt.chk_picture.disabled = !isReset
        extraOpt.chk_wine.disabled = !isReset
        extraOpt.chk_insurance.disabled = !isReset
        this.$refs.extraEtc.disabled = !isReset
      }
    },
    section5(param) {
      const isClose = param.isClose
      const isReset = param.isReset
      
      if(isClose === true) {
        this.$refs.section5_y.style.display = 'none'
        this.$refs.section5.style.background = '#FFF7F6'
        this.$refs.section5_mod.style.display = ''
        this.$refs.addItem.style.display = 'none'
      } else {
        this.$refs.section5_y.style.display = ''
        this.$refs.section5.style.background = 'white'
        this.$refs.section5_mod.style.display = 'none'
        this.$refs.addItem.style.display = ''
        this.$refs.section6.style.display = 'none'
      }
      if(this.items.length !== 0) {
        let orderInputList = this.$refs.itemsDiv.getElementsByTagName('input')
  
        for(var i=0; i<orderInputList.length; i++) {
          const orderInput = orderInputList[i]
          orderInput.disabled = !isReset
        }
      }

      if(isReset === true) {
        this.$refs.section5_y.style.display = ''
        this.$refs.section5.style.background = 'white'
        this.$refs.section5_mod.style.display = 'none'
        // this.$refs.recentBtn.style.display = ''
      }
    },
    section3(param) {
      const isClose = param.isClose
      const isReset = param.isReset
      
      if(isClose === true) {
        this.$refs.section3_y.style.display = 'none'
        this.$refs.section3_y.style.cursor = 'not-allowed'
        this.$refs.section3.style.background = '#FFF7F6'
        this.$refs.section3_mod.style.display = ''
        this.$refs.recentBtn.style.display = 'none'
      } else {
        this.$refs.section3_y.style.display = ''
        this.$refs.section3_y.style.cursor = ''
        this.$refs.section3.style.background = 'white'
        this.$refs.section3_mod.style.display = 'none'
        this.$refs.recentBtn.style.display = ''
      }
      if(isReset === true) {
        this.$refs.section3_y.style.display = ''
        this.$refs.section3_y.style.cursor = ''
        this.$refs.section3.style.background = 'white'
        this.$refs.section3_mod.style.display = 'none'
        // this.$refs.recentBtn.style.display = ''
      }
      this.$refs.receiver.$refs.country.disabled = !isReset
      this.$refs.receiver.$refs.name.disabled = !isReset
      this.$refs.receiver.$refs.clearance.disabled = !isReset
      this.$refs.receiver.$refs.phone.disabled = !isReset
      this.$refs.receiver.$refs.mail.disabled = !isReset
      this.$refs.receiver.$refs.addr_zip.disabled = !isReset
      this.$refs.receiver.$refs.addr_road.disabled = !isReset
      this.$refs.receiver.$refs.addr_detail.disabled = !isReset 
    },
    recentInfo() {
      if(this.api.getCookies('id')) {
        this.showRecentModal()
      } else {
        this.emitter.emit('confirm', {message: '로그인 후 다시 시도해주세요', header:'대행신청', icon:'pi pi-exclamation-triangle'})
        this.$emit('login')
      }
    },
    setRecentModal(orderInfo) {
      this.$refs.receiver.$refs.name.value = orderInfo.name
      this.$refs.receiver.$refs.clearance.value = orderInfo.clearance
      this.$refs.receiver.$refs.phone.value = orderInfo.phone
      this.$refs.receiver.$refs.mail.value = orderInfo.mail
      this.$refs.receiver.$refs.addr_detail.value = orderInfo.addrDetail
      this.$refs.receiver.$refs.addr_road.value = orderInfo.addrRoad
      this.$refs.receiver.$refs.addr_zip.value = orderInfo.addrZip
    },    
    remove(idx) {
      idx--;
      let order_list = document.querySelectorAll("#orderItem .dynamic-table");
      let order_value_list = [];
      for(var i=0; i<order_list.length; i++) {
        if(i !== idx) {
          let input_list = order_list[i].querySelectorAll("#orderItem .normalInput");

          const url = input_list[0].value
          const name = input_list[1].value
          const imgUrl = input_list[2].value
          const price = input_list[3].value
          const amount = input_list[4].value
          const orderNumber = input_list[5].value
          const trackingNumber = input_list[6].value
          let wonPrice = ''
          if(input_list[7] !== undefined) wonPrice = input_list[7].value 
          const brand = input_list[8].value

          order_value_list[order_value_list.length] = {"url":url,"name":name,"imgUrl":imgUrl, "price":price,"amount":amount,"orderNumber":orderNumber,"trackingNumber":trackingNumber,"wonPrice":wonPrice, "brand":brand}
        }
      }
      this.items.splice(idx, 1)
      order_list = document.querySelectorAll("#orderItem .dynamic-table");
      
      for(var j=0; j<order_value_list.length; j++) {
        let input_list = order_list[j].querySelectorAll("#orderItem .normalInput")
        var json = order_value_list[j]
        input_list[0].value = json.url
        input_list[1].value = json.name
        input_list[2].value = json.imgUrl
        input_list[3].value = json.price
        input_list[4].value = json.amount
        input_list[5].value = json.orderNumber
        input_list[6].value = json.trackingNumber
        if(input_list[7] !== undefined) input_list[7].value = json.wonPrice
        input_list[8].value = json.brand
      }
    },
    async copy(idx) {
      await this.add_item()
      idx--
      let order_list = document.querySelectorAll("#orderItem .dynamic-table")
      let input_list = order_list[idx].querySelectorAll("#orderItem .normalInput")

      const url = input_list[0].value
      const name = input_list[1].value
      const imgUrl = input_list[2].value
      const price = input_list[3].value
      const amount = input_list[4].value
      const orderNumber = input_list[5].value
      const trackingNumber = input_list[6].value
      let wonPrice = ''
      if(input_list[7] !== undefined) wonPrice = input_list[7].value 
      const brand = input_list[8].value

      input_list = order_list[order_list.length-1].querySelectorAll("#orderItem .normalInput")

      input_list[0].value = url
      input_list[1].value = name
      input_list[2].value = imgUrl
      input_list[3].value = price
      input_list[4].value = amount
      input_list[5].value = orderNumber
      input_list[6].value = trackingNumber
      if(input_list[7] !== undefined) input_list[7].value = wonPrice
      input_list[8].value = brand
    },    
    reload(flag) {
      if(flag === 'dashboard') {
        this.helperKey++
        this.$emit("setMenu", {page:'helper'})
      }
    },
    // async request() {
    //   window.Kakao.API.request({
    //     url: '/v2/user/me',

    //     success: async (response) => {
    //       let flag = false
    //       await this.api.axiosGql('SELECT_COMMON_USER', {type: 'SELECT_COMMON_USER', id:response.id })
    //       .then( async (res) => {
    //         const result = res.data
    //         if(result === 'null' || result === undefined || result === null ) flag = false
    //         else flag = true
    //       })
    //       .catch((err) => {
    //         console.log(err)
    //       })
    //       if(flag) {
    //         this.emitter.emit("setCookies", {login:true, id:response.id, nickname:response.properties.nickname, img:response.properties.thumbnail_image, mail:response.kakao_account.email})
    //       } else this.showRegistModal(response.id)
    //     },
    //     fail: (error) => {
    //       alert("다시 시도해주세요.")
    //       console.log(error)
    //     }
    //   })
    // },
    // async login() {
    //   window.Kakao.Auth.login({
    //     success: () => {
    //       this.tmp = '로그인'
    //       this.request()
    //     },
    //     fail: () => {
    //       alert("다시 시도해주세요.")
    //     },
    //   })
    // },
    async click(param) {
      const type = param.type
      if(type === 'section1') {
        const val = param.value
        if(val === 'y') {
          this.$refs.section1.style.background = '#FFF7F6'
          this.$refs.section1_n.style.display = 'none'
          this.$refs.section1_y.style.cursor = 'not-allowed'
          this.$refs.section1_y.disabled = true
          this.$refs.section2.style.display = ''
        } else {
          this.emitter.emit('confirm', {message: '대행 진행을 취소하겠습니다', header:'대행신청', icon:'pi pi-exclamation-triangle'})
          router.push(({name:"list"}))
        }
      } else if(type === 'section2') {
        const val = param.value
        if(val === 'buy') {
          this.$refs.section2_dlv.style.display = 'none'
          this.$refs.section2_pay.style.display = 'none'
          this.$refs.section2_buy.style.cursor = 'not-allowed'
          this.$refs.section2_buy.disabled = true
          this.$refs.section2.style.background = '#FFF7F6'
          this.$refs.section3.style.display = ''
          this.$refs.section2_mod.style.display = ''
          this.orderType = '구매대행'
          this.section3({isClose:false, isReset:true})
          this.$refs.section3.focus()
        } else if(val === 'dlv'){
          this.$refs.section2_buy.style.display = 'none'
          this.$refs.section2_pay.style.display = 'none'
          this.$refs.section2_dlv.style.cursor = 'not-allowed'
          this.$refs.section2_dlv.disabled = true
          this.$refs.section2.style.background = '#FFF7F6'
          this.$refs.section3.style.display = ''
          this.$refs.section2_mod.style.display = ''
          this.orderType = '배송대행'
          this.section3({isClose:false, isReset:true})
          this.$refs.section3.focus()
        } else if(val === 'pay'){
          this.$refs.section2_buy.style.display = 'none'
          this.$refs.section2_dlv.style.display = 'none'
          this.$refs.section2_pay.style.cursor = 'not-allowed'
          this.$refs.section2_pay.disabled = true
          this.$refs.section2.style.background = '#FFF7F6'
          this.$refs.section4.style.display = ''
          this.$refs.section2_mod.style.display = ''
          this.orderType = '결제대행'
          this.$refs.section4.focus()
        } else if(val === 'mod') {
          this.$refs.section2_dlv.style.display = ''
          this.$refs.section2_dlv.style.cursor = ''
          this.$refs.section2_buy.style.display = ''
          this.$refs.section2_buy.style.cursor = ''
          this.$refs.section2_pay.style.display = ''
          this.$refs.section2_pay.style.cursor = ''
          this.$refs.section2_buy.disabled = false
          this.$refs.section2_dlv.disabled = false
          this.$refs.section2_pay.disabled = false
          this.$refs.section2.style.background = 'white'
          this.$refs.section2.style.background = 'white'
          this.$refs.section3.style.display = 'none'
          this.$refs.section4.style.display = 'none'
          this.$refs.section5.style.display = 'none'
          this.$refs.section6.style.display = 'none'
          this.$refs.section2_mod.style.display = 'none'
          this.orderType = ''
          this.section3({isClose:false, isReset:true})
          this.section5({isClose:false, isReset:true})
          this.section6({isClose:false, isReset:true})
        }
      } else if(type === 'section3') {
        const val = param.value
        if(val === 'y') {
          const typeTag = this.$refs.receiver.$refs.type
          const countryTag = this.$refs.receiver.$refs.country
          const nameTag = this.$refs.receiver.$refs.name
          const clearanceTag = this.$refs.receiver.$refs.clearance
          const phoneTag = this.$refs.receiver.$refs.phone
          const mailTag = this.$refs.receiver.$refs.mail
          const addrZipTag = this.$refs.receiver.$refs.addr_zip
          const addrRoadTag = this.$refs.receiver.$refs.addr_road
          const addrDetailTag = this.$refs.receiver.$refs.addr_detail
  
          const type = typeTag.value
          const country = countryTag.value
          const name = nameTag.value
          const clearance = clearanceTag.value
          const phone = phoneTag.value
          const mail = mailTag.value
          const addrZip = addrZipTag.value
          const addrRoad = addrRoadTag.value
          const addrDetail = addrDetailTag.value
  
          let isReceiverValid = true
          if(type === '') {
            alert('대행유형을 선택해주세요')
            typeTag.focus()
            isReceiverValid = false
          } else if(country === '') {
            alert('국가를 선택해주세요')
            countryTag.focus()
            isReceiverValid = false
          } else if(name === '') {
            alert('받는 사람 이름을 입력해주세요')
            nameTag.focus()
            isReceiverValid = false
          } else if(clearance === '') {
            alert('통관부호를 입력해주세요')
            clearanceTag.focus()
            isReceiverValid = false
          } else if(clearance.length !== 13 || (clearance.indexOf('p') === -1 && clearance.indexOf('P') === -1)) {
            alert("통관부호를 정확하게 입력해 주세요");
            clearanceTag.focus()
            isReceiverValid = false
          } else if(phone === '') {
            alert('받는 사람 연락처를 입력해주세요')
            phoneTag.focus()
            isReceiverValid = false
          } else if(mail === '') {
            alert('받는 사람 메일을 입력해주세요')
            mailTag.focus()
            isReceiverValid = false
          } else if(addrZip === '' || addrRoad === '') {
            alert('받는 사람 주소를 입력해주세요')
            addrZipTag.focus()
            isReceiverValid = false
          }
          if(country === '대만' && type === '배송대행') {
            this.emitter.emit('confirm', {message: '대만은 구매대행만 가능합니다.', header:'대행신청', icon:'pi pi-exclamation-triangle'})
            return
          }
          if(isReceiverValid === true) {
            this.orderType = type
            this.country = country
            this.orderName = name
            this.clearance = clearance
            this.phone = phone
            this.mail = mail
            this.addrZip = addrZip
            this.addrRoad = addrRoad
            this.addrDetail = addrDetail
            this.$refs.section5.style.display = ''
            this.$refs.recentBtn.style.display = 'none'
            this.section3({isClose:true, isReset:false})
            this.section5({isClose:false, isReset:true})
            this.section6({isClose:false, isReset:true})
            this.$refs.section5.focus()
          }
        } else if(val === 'mod') {
          this.$refs.section5.style.display = 'none'
          this.$refs.section6.style.display = 'none'
          this.section3({isClose:false, isReset:true})
          this.section5({isClose:false, isReset:true})
          this.section6({isClose:false, isReset:true})
        }
      } else if(type === 'section4') {
        const val = param.value
        if(val === 'y') {
          const id = this.api.getCookies('id')
          // const mail = this.api.getCookies('mail')
          if(id === undefined || id == null) {
            this.$notify('로그인해주세요.')
            alert('로그인해주세요.')
            return
          }
          const nameInput = this.$refs.payName
          const phoneInput = this.$refs.payPhone
          const contentInput = this.$refs.payContent

          if(nameInput.value === undefined || nameInput.value === '') {
            this.$notify('성함을 입력해주세요')
            alert('입금자명을 입력해주세요')
            nameInput.focus()
          } else if(phoneInput.value === undefined || phoneInput.value === '') {
            this.$notify('폰번호를 입력해주세요')
            alert('폰번호 입력해주세요')
            phoneInput.focus()
          } else if(contentInput.value === undefined || contentInput.value === '') {
            this.$notify('문의내용을 입력해주세요')
            alert('문의내용을 입력해주세요')
            contentInput.focus()
          } else {
            this.emitter.emit('dialog', {flag:true, title:'등록중입니다.'})
            await this.api.axiosGql('INSERT_QNA', {type:'INSERT_QNA', id:id, orderList:'', content:contentInput.value, phone:phoneInput.value, mail:this.$refs.receiver.$refs.mail.value, orderName: nameInput.value, fileName:this.fileName})
            .then( async (res) => {
              if(res.status === 200) {
                this.$notify('결제대행요청이 등록되었습니다.')
                alert('결제대행요청이 등록되었습니다.')
                router.push(({name:"qna", params:{page: 'helper'}}))   
                this.emitter.emit('dialog', {flag:false})
              }
            })
            .catch( (err) => {
              console.log(err)
              this.$notify('다시 시도해주세요.')
              alert('다시 시도해주세요.')
            })
          }
        } else if(val === 'mod') {
          this.$refs.section5.style.display = 'none'
          this.$refs.section6.style.display = 'none'
          this.section5({isClose:false, isReset:true})
          this.section6({isClose:false, isReset:true})
        }
      } else if(type === 'section5') {
        const val = param.value
        if(val === 'y') {
          let orderList = new Array()
          let order_list = document.querySelectorAll("#orderItem .dynamic-table");
          // let etc = ''
          // let price = 0
          // let country = ''

          if(order_list === undefined || order_list.length == 0) {
            alert('대행 상품을 추가해주세요')
            this.$notify('대행 상품을 추가해주세요')
            return
          } else {
            for(var k=0; k<order_list.length; k++) {
              let input_list = order_list[k].querySelectorAll("#orderItem .normalInput");
              let orderJson = new Object()
              for(var j=0; j<input_list.length; j++) {
                const value = input_list[j].value
                if(j === 3 && value > 99999999 ) {
                  alert( (k+1) + "번째 상품의 해외 가격이 너무 높습니다.");
                  input_list[j].focus()
                  this.disableBtn(false)
                  return
                } else if( '' === value && j !== 2 &&  j !== 6 &&  j !== 7 &&  j !== 8 &&  j !== 9) {
                  alert( (k+1) + "번째 상품 정보 중 누락된 정보가 있습니다")
                  input_list[j].focus()
                  return
                } else if(j === 0) {
                  if(value.indexOf(',') !== -1) {
                    alert( '상품링크는 하나씩만 입력해야 됩니다. 상품이 여러 개인 경우 "상품추가"를 해서 등록해주시기 바랍니다.');
                    input_list[j].focus()
                    this.disableBtn(false)
                    return
                  }
                  if(value.indexOf('carousell') === -1 || value.indexOf('shopee') === -1) orderJson.URL = value 
                  else orderJson.URL = this.api.shortenUrl(value)
                }
                else if(j === 1) orderJson.NAME = value
                else if(j === 2) {
                  if(value.indexOf(',') !== -1) {
                    alert( '사진링크는 하나씩만 입력해야 됩니다. 상품이 여러 개인 경우 "상품추가"를 해서 등록해주시기 바랍니다.');
                    input_list[j].focus()
                    window.scrollTo(0, 0)
                    this.disableBtn(false)
                    return
                  }
                  orderJson.IMG_URL = value
                }
                else if(j === 3) orderJson.ORIGIN_PRICE = value
                else if(j === 4) {
                  let intAmount = value
                  intAmount = intAmount *= 1
                  if(intAmount === undefined || intAmount <= 0) {
                    alert('구매수량을 입력해주시기 바랍니다.')
                    input_list[j].focus()
                    window.scrollTo(0, 0)
                    this.disableBtn(false)
                    return
                  }
                  orderJson.AMOUNT = intAmount
                }
                else if(j === 5) orderJson.ORDER_NUMBER = value
                else if(j === 6) orderJson.TRACKING_NUMBER = value
                else if(j === 7) {
                  this.sellPrice = parseInt(value)
                  orderJson.SELL_PRICE = this.sellPrice
                } else orderJson.BRAND = value
              }
              // orderJson.NOTICE = this.etc
              orderJson.COUNTRY = this.country
              orderJson.ORDER_TYPE = this.orderType
              orderList.push(orderJson)
              this.orderArray = orderList
            }
            // TODO
            // console.log(orderArray)
            this.$refs.section6.style.display=''
            this.section5({isClose:true, isReset:false})
            this.$refs.section6.focus()
          }
        } else if(val === 'mod') {
          this.$refs.section6.style.display='none'
          this.section5({isClose:false, isReset:true})
          this.section6({isClose:false, isReset:true})
        }
      } else if(type === 'section6') {
        const val = param.value
        if(val === 'y') {

          const opt_tag = this.$refs.extraOption.$refs
          // if(opt_tag.chk_fta.checked === true) this.etc += '원산지증명 만원,'
          if(opt_tag.chk_picture.checked === true) {
            this.etc += '사진촬영비용 5000원,'
            this.picturePrice = '5000'
          }
          if(opt_tag.chk_packing.checked === true) {
            this.etc += '포장보완비용 5000원,'
            this.packagePrice = '5000'
          }
          if(opt_tag.chk_insurance.checked === true) {
            if(this.orderType !== '구매대행') {
              this.emitter.emit('confirm', {message: '구매대행 주문만 보험 신청이 가능합니다', header:'수정', icon:'pi pi-exclamation-triangle'})
              this.disableBtn(false)
              return
            } else {
              this.etc += '보험 30000원, '
              this.insurancePrice = '30000'
            }
          }
          if(opt_tag.chk_wine.checked === true) {
            if(this.country !== '프랑스') {
              this.emitter.emit('confirm', {message: '와인대행은 프랑스만 가능합니다', header:'와인대행', icon:'pi pi-exclamation-triangle'})
              this.disableBtn(false)
              return
            } else this.etc += '와인 8000원 x 수량,'
          }
          this.etc += this.$refs.extraEtc.value
          this.section6({isClose:true, isReset:false})
        } else if(val === 'mod') {
          this.$refs.helperRegBtn.style.display = ''
          this.section6({isClose:false, isReset:true})
        }
      } else if(type === 'section1-notice') {
        this.subIdx = 0
        this.idx = '-1'
        this.showModal()
      } else if(type === 'section2-notice') {
        this.subIdx = 1
        this.idx = '-1'
        this.showModal()
      } else if(type === 'section3-notice') {
        this.subIdx = 3
        this.idx = '-1'
        this.showModal()
      } else if(type === 'section4-notice') {
        this.subIdx = 4
        this.idx = '-1'
        this.showModal()
      } else if(type === 'section5-notice') {
        this.subIdx = 5
        this.idx = '-1'
        this.showModal()
      } else if(type === 'section6-notice') {
        this.subIdx = 6
        this.idx = '-1'
        this.showModal()
      } else if(type === 'reg') {
        this.disableBtn(true)
        const loginId = this.api.getCookies('id')
        for(var i=0; i<this.orderArray.length; i++) {
          this.orderArray[i].NOTICE = this.etc
        }
        if(loginId) {
          let orderInfoJson = {type:'INSERT_USER', mail: this.mail, addrZip: this.addrZip, addrRoad: this.addrRoad, addrDetail: this.addrDetail, name: this.orderName, clearance: this.clearance, phone: this.phone, id:loginId}
          await this.api.axiosGql('INSERT_USER', orderInfoJson)
          .then( (res) => {
            if(res.data === '') console.log('Already Existed User')
            else if(res.data.NAME === this.orderName ) console.log('INSERT_USER SUCCESS')
            else {
              alert('다시 시도해주세요.')  
              this.disableBtn(false)
              return
            }
            orderInfoJson.type = 'INSERT_ORDER_INFO'
            let orderDtlUid = ''
            this.api.axiosGql('INSERT_ORDER_INFO', orderInfoJson)
            .then( async (res) => {
              const uid = res.data.UID
              if(uid !== null ) {
                this.orderArray.map(json => { 
                  json.UID = uid
                  orderDtlUid = uid
                  json.ID = loginId
                  if(json.BRAND === undefined || json.BRAND === '') {
                    let split = String(json.URL).split('.')
                    json.BRAND = split[1]
                    if(json.BRAND.indexOf('com') !== -1 || json.BRAND.indexOf('net') !== -1) {
                      json.BRAND = split[0].replace(/http:\/\//g, '').replace(/https:\/\//g, '')
                    }
                  } else if(json.BRAND.length > 15) json.BRAND = json.BRAND.substring(0, 15)
                  json.REG_DATE = moment(new Date()).format('YYYYMMDDhhmm')
                  json.ORDER_DATE = moment(new Date()).format('YYYYMMDD')
                  json.ETC = 'helper'
                })

                let paramArray = new Array()
                for(var i = 0; i < this.orderArray.length; i++) {
                  const orderJson = this.orderArray[i]
                  paramArray.push(JSON.stringify(orderJson))
                }
                const formData = new FormData()
                formData.append("list", paramArray)
                formData.append('slack', '접수')
                formData.append('type', 'INSERT_ORDER_LIST')

                await this.api.axiosGql('INSERT_ORDER_LIST', formData)
                .then( async (res) => {
                  if(res.data !== undefined ) {
                    this.emitter.emit('dialog', {flag:false})
                    if(this.sellPrice > 0) this.emitter.emit('confirm', {message: '등록되었습니다. 주문조회를 하시면 진행상황을 확인하실 수 있습니다', header:'대행신청', icon:'pi pi-exclamation-triangle'})
                    else alert('등록되었습니다!')
                    router.push(({name:"list"}))
                    // TDOD START : INSERT_ORDER_PRICE_DETAIL 
                    if(!(orderDtlUid === undefined || orderDtlUid === '')) {
                      this.api.axiosGql('INSERT_ORDER_PRICE_DTL', {type:'INSERT_ORDER_PRICE_DTL', uid:orderDtlUid, picturePrice:this.picturePrice, packagePrice:this.packagePrice, insurancePrice:this.insurancePrice})
                    }
                    // TDOD END : INSERT_ORDER_PRICE_DETAIL
                    
                  } else {
                    this.emitter.emit('confirm', {message: '다시 시도해주세요', header:'대행신청', icon:'pi pi-exclamation-triangle'})
                    this.disableBtn(false)
                    return
                  }
                })
                .catch( (err) => {
                  this.disableBtn(false)
                  this.emitter.emit('confirm', {message: '다시 시도해주세요', header:'대행신청', icon:'pi pi-exclamation-triangle'})
                  console.log(err)
                })
              } else {
                this.disableBtn(false)
                this.emitter.emit('confirm', {message: '다시 시도해주세요', header:'대행신청', icon:'pi pi-exclamation-triangle'})
                return
              }
            })
            .catch( (err) => {
              this.disableBtn(false)
              this.emitter.emit('confirm', {message: '다시 시도해주세요', header:'대행신청', icon:'pi pi-exclamation-triangle'})
              console.log(err)
            })
          })
          .catch( (err) => {
            this.disableBtn(false)
            this.emitter.emit('confirm', {message: '다시 시도해주세요', header:'대행신청', icon:'pi pi-exclamation-triangle'})
            console.log(err)
          }) 
        }
      }
    },
    disableBtn(flag) {
      this.$refs.helperRegBtn.disabled = flag
      this.emitter.emit('dialog', {flag:flag, title:'등록중입니다.'})
      flag === false ? this.$refs.helperRegBtn.style.backgroundColor = '#ffa07a' : this.$refs.helperRegBtn.style.backgroundColor = '#ccc'
    },
    async readFile(event) {
      this.file = event.target.files[0]
      if(this.file.size >= 3072000) alert('3M 이하 파일만 첨부가 가능합니다')
      else {
        const type = 'UPLOAD_IMG'
        const formData = new FormData()
        formData.append("file", this.file)
        formData.append('service', 'geek9')
        formData.append('pbType', 'qna')
        formData.append('type', type)
        await this.api.axiosGql(type, formData)
        .then( async (res) => {
          this.fileName = res.data
          const preview = document.querySelector('#helpPic')
          preview.innerHTML = '첨부완료'
        })
      }  
    },
  }
}
</script>

<style scoped>

.notice {
  float:left;
  margin-left:20px;
  margin-top:15px;
  font-weight: bold;
}
.question {
  line-height: 2;
  margin: 10px;
}
.answer {
  margin: 10px;
  color:red;
  font-weight: bold;
  text-align: right;
}
.button_regist {
  background-color: lightsalmon;  
  color: white;
  padding: 10px;
  font-weight: bold;
  border-radius: 9px;
  border: 0px;
  min-width: 100px;
  cursor: pointer;
  margin-top: 25px;
  margin-bottom: 10px;
}
.helper-button {
  border-radius: 9px;
  padding:5px;
  cursor: pointer;
  font-style:italic;
  border: 1px solid lightsalmon;
  background-color: white;
  width:15%;
  margin-left: 10px;
}
.group {
  border-radius: 9px;
  padding:10px;
  margin-top:30px;
  border: 2px dotted #FED8B1;
}
textarea {
  width: 100%;
  height: 200px;
  resize: none;
}
/* .pay-input {
  min-width:100px;
  max-width:95%;
} */
.attach_label {
  float:right;
  border: 0px;
  padding:5px;
}
.attach {
  float:right;
  border-radius: 9px;
  font-style: italic;
  padding:5px;
  max-width: 70px;
  cursor: pointer;
}
</style>